import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql , Link } from "gatsby"
import SecHeader from "../components/secondHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"

import newspaper from '../images/Newspaper1.jpg'




const News = () => (

<StaticQuery
    query={graphql`
       query postsQuery{
        allWordpressPost{
            edges{
                node{
                    id
                    title
                    excerpt
                    slug
                    date(formatString: "MMMM DD, YYYY")
                    featured_media {
                         localFile{
                            childImageSharp{
                                resolutions(width:900, height: 500){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                     }
                }
            }
        }
    }


    
    `}


    render={data => (




        <Layout>
          <SecHeader pageTitle="News" image={newspaper} />
            <div class="uk-container uk-section">
                <h1>Posts</h1>

                {data.allWordpressPost.edges.map(({node}) => (
                    <div key={node.slug} className={"post"} style={{ marginBottom: 50 }}>
                        <Link to={'post/' + node.slug}>
                            <h3>{node.title}</h3>
                        </Link>
                         <p><img src={node.featured_media.localFile.childImageSharp.resolutions.src} /></p>
                        <div className={"post-content"} dangerouslySetInnerHTML={{__html: node.excerpt}} />
                       
                        {node.date}
                        </div>

                ))}

            </div>

            </Layout>

        )}
   

    
    />

)

News.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default News


